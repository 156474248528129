import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import formatPhoneNumber from '../utils/formatPhoneNumber' 
import Seo from 'gatsby-plugin-wpgraphql-seo';
import useScript from "../hooks/useScript";
import mapMarker from "../assets/img/map-marker.png"

export default function Careers({ data }) {
  const page = data.allWpPage.nodes[0];
  const [scriptLoaded, setScriptLoaded] = useState(false);

  //load in the google maps script via our useScript hook
  useScript(
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBRD-ysASOP5Ki-ogoQB4KHd8_m2vbQfDs&callback=initMap"
  );

  if (typeof window !== 'undefined') {
	
    window.initMap = function() {
	  setScriptLoaded(true);
	  //console.log('in initMap');
    }
  }

  useEffect(() => {
    //console.log('in contact use effect')
	if (scriptLoaded || (window && window.google?.maps) ) {
      //console.log('script is loaded, adding map')
		
	  const coord = {
	    lat: 51.495520,
        lng: -0.145830
	  };
	
	  const map = new window.google.maps.Map(document.getElementById('map'), {
        center: coord,
        zoom: 16,
        styles: [{"featureType": "administrative","elementType": "all","stylers": [{"saturation": "-100"}]},{"featureType": "administrative.province","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType": "landscape","elementType": "all","stylers": [{"saturation": -100},{"lightness": 65},{"visibility": "on"}]},{"featureType": "poi","elementType": "all","stylers": [{"saturation": -100},{"lightness": "50"},{"visibility": "simplified"}]},{"featureType": "road","elementType": "all","stylers": [{"saturation": "-100"}]},{"featureType": "road.highway","elementType": "all","stylers": [{"visibility": "simplified"}]},{"featureType": "road.arterial","elementType": "all","stylers": [{"lightness": "30"}]},{"featureType": "road.local","elementType": "all","stylers": [{"lightness": "40"}]},{"featureType": "transit","elementType": "all","stylers": [{"saturation": -100},{"visibility": "simplified"}]},{"featureType": "water","elementType": "geometry","stylers": [{"hue": "#ffff00"},{"lightness": -25},{"saturation": -97}]},{"featureType": "water","elementType": "labels","stylers": [{"lightness": -25},{"saturation": -100}]}]
      })

	  const image = {
	    url: mapMarker,
	    size: new window.google.maps.Size(75, 100),
   	    scaledSize: new window.google.maps.Size(75, 100),
	    origin: new window.google.maps.Point(0, 0),
	    anchor: new window.google.maps.Point(37.5, 100),
	  };

      new window.google.maps.Marker({
        position: coord,
        icon: image,
        animation: window.google.maps.Animation.DROP,
        map
      });
	}
  }, [scriptLoaded]);

  return (
    <Layout className="page-contact">	
      <Seo post={page} />
	  <div className="page-widgets">
	    <Hero widget={{layout : 'mini'}} />
	  </div>
	  <article>
		<header className="internal">
			<h1>{page.title}</h1>
		</header>
		<div className="internal">
			<section className="contact-us">
				{page.contact?.contactBlocks?.length && page.contact.contactBlocks.map((block, i) => (
				    <div className="block" key={`block${i}`}>
						<h3>{block.title}</h3>
						<p className="subtitle">{block.subtitle}</p>
						{block.phone && (
						  <p className="phone"><span>Call </span><a href={formatPhoneNumber(block.phone)}>{block.phone}</a></p>
					    )}
						{block.email && (
						  <p className="email"><span>Email  </span><a href={`mailto:${block.email}`}>{block.email}</a></p>
					    )}
						{block.text && (
						  <p className="text">{block.text}</p>
					    )}
						{block.links?.length && (
							<p className="links">
								{block.links.map(({link}, j) => (
									<Link to={link.url} key={`link${j}`}>{link.title}</Link>
								))}
							</p>
						)}
				    </div>
			    ))}
			</section>
		</div>
		<section className="find-us">
			<div className="internal columns">
				<h2>{page.contact?.contactMap?.title}</h2>
				<div className="address">
					<h3>{page.contact?.contactMap?.companyName}</h3>
					<p dangerouslySetInnerHTML={{ __html: page.contact.contactMap.address }} />
				</div>
			</div>
			<div id="map" />
		</section>
	  </article>
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        ...PageSEOFragment
		contact {
	      contactBlocks {
	        title
	        subtitle
	        phone
	        email
	        text
	        links {
	          link {
	            target
	            title
	            url
	          }
	        }
	      }
	      contactMap {
	        title
	        companyName
	        address
	      }
	    }
      }
    }
  }
`